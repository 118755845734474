import ar from './ar.json';

export const __ = (key) => {
    if (getLang() !== 'ar') {
        return key;
    }

    let translation = ar[key];

    if (!translation) {
        return key.charAt(0).toUpperCase() + key.slice(1);
    }

    return translation;
};

export const getLang = () => {
    return new URLSearchParams(window.location.search).get('lang') || 'ar';
};
