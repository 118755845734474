import React from 'react';
import { __ } from '../../Helpers/Str';
import { Button } from 'react-bootstrap';

const Preview = () => {

    const onClick = () => {
        const id = 'connect-us';
        const yOffset = -50;
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: 'smooth' });
    };

    return (
        <div className="preview" id="home">
            <div className="preview-container">
                <div className="preview-content">
                    <h1 className="text-primary mb-5">{__('Jsour')}</h1>
                    <Button onClick={onClick} className="d-flex justify-content-center">{__('Start with us')}</Button>
                </div>
            </div>
        </div>
    );
};

export default Preview;
