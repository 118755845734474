import React, { useState } from 'react';
import { __, getLang } from '../../Helpers/Str';
import { Button, Col, Form } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import * as Sentry from '@sentry/react';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const ContactForm = () => {
    const [message, setMessage] = useState({});
    const [errors, setErrors] = useState({});
    const [sending, setSending] = useState(false);

    const onChange = (e) => setMessage({ ...message, [e.target.name]: e.target.value });

    const onSubmit = (e) => {
        e.preventDefault();

        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
        });

        setSending(true);
        setErrors({});

        const data = {
            ...message,
            request_type: 499,
        };

        axios
            .post(`create?lang=${getLang()}`, data)
            .then(() => {
                Toast.fire({
                    title: __('Your letter sent successfully'),
                    icon: 'success',
                });

                setMessage({});
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 400 && error.response.data.errors) {
                        setErrors(error.response.data.errors);
                    } else {
                        Sentry.captureException(error);
                        Toast.fire({
                            icon: 'error',
                            title: __('Something wrong happened'),
                        });
                    }
                } else {
                    Sentry.captureException(error);
                    Toast.fire({
                        icon: 'error',
                        title: __('Something wrong happened'),
                    });
                }
            })
            .finally(() => setSending(false));
    };

    const renderErrors = (key) =>
        isInvalid(key) ? (
            <Form.Control.Feedback type="invalid">
                <ul>{errors[key]}</ul>
            </Form.Control.Feedback>
        ) : null;

    const isInvalid = (key) => (errors ? errors[key] : false);

    return (
        <Form
            onSubmit={onSubmit}
            className={['shadow-sm', getLang() === 'ar' ? 'text-right' : 'text-left'].join(' ')}
            dir={getLang() === 'ar' ? 'rtl' : 'ltr'}
        >
            <h3 className="mb-4">{__('Write your letter')}</h3>
            <Form.Row className="mb-3">
                <Form.Group as={Col} lg={6}>
                    <Form.Label className="required">{__('First name')}</Form.Label>
                    <Form.Control
                        type="text"
                        name="first_name"
                        isInvalid={isInvalid('first_name')}
                        value={message.first_name || ''}
                        onChange={onChange}
                        placeholder={__('First name')}
                        disabled={sending}
                        required
                    />
                    {renderErrors('first_name')}
                </Form.Group>
                <Form.Group as={Col} lg={6}>
                    <Form.Label className="required">{__('Last name')}</Form.Label>
                    <Form.Control
                        type="text"
                        name="last_name"
                        isInvalid={isInvalid('last_name')}
                        value={message.last_name || ''}
                        onChange={onChange}
                        placeholder={__('Last name')}
                        disabled={sending}
                        required
                    />
                    {renderErrors('last_name')}
                </Form.Group>
            </Form.Row>
            <Form.Row className="mb-3">
                <Form.Group as={Col} lg={6}>
                    <Form.Label className="required">{__('Mobile number')}</Form.Label>
                    <Form.Control
                        type="text"
                        name="mobile_number"
                        isInvalid={isInvalid('mobile_number')}
                        value={message.mobile_number || ''}
                        onChange={onChange}
                        placeholder={__('Mobile number')}
                        disabled={sending}
                        required
                    />
                    {renderErrors('mobile_number')}
                </Form.Group>
                <Form.Group as={Col} lg={6}>
                    <Form.Label className="required">{__('Email address')}</Form.Label>
                    <Form.Control
                        type="email"
                        name="email"
                        isInvalid={isInvalid('email')}
                        value={message.email || ''}
                        onChange={onChange}
                        placeholder={__('Email address')}
                        disabled={sending}
                        required
                    />
                    {renderErrors('email')}
                </Form.Group>
            </Form.Row>
            <Form.Row className="mb-3">
                <Form.Group as={Col}>
                    <Form.Label className="required">{__('Company name')}</Form.Label>
                    <Form.Control
                        type="text"
                        name="company_name"
                        isInvalid={isInvalid('company_name')}
                        value={message.company_name || ''}
                        onChange={onChange}
                        placeholder={__('Company name')}
                        disabled={sending}
                        required
                    />
                    {renderErrors('company_name')}
                </Form.Group>
            </Form.Row>
            <Form.Row className="mb-3">
                <Form.Group as={Col}>
                    <Form.Label className="required">{__('Message')}</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows="5"
                        name="description"
                        value={message.description || ''}
                        onChange={onChange}
                        isInvalid={isInvalid('description')}
                        placeholder={__('Message')}
                        disabled={sending}
                        required
                    />
                    {renderErrors('description')}
                </Form.Group>
            </Form.Row>
            <Button type="submit" disabled={sending} size="lg" block>
                {sending ? __('Sending...') : __('Send')}
            </Button>
        </Form>
    );
};

export default ContactForm;
