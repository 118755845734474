import React, { Fragment, useState } from 'react';
import NavBar from './NavBar';
import Preview from './Preview';
import AboutUs from './AboutUs';
import WhyMdad from './WhyMdad';
import Offers from './Offers';
import Footer from './Footer';
import ModalForm from './ModalForm';
import CalendlyModal from './CalendlyModal';
import * as Sentry from '@sentry/react';

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: 'jsours@' + process.env.REACT_APP_VERSION,
});

const Home = () => {
    const [show, setShow] = useState(false);
    const [calendlyModalShow, setCalendlyModalShow] = useState(false);
    const [packageName] = useState('');

    return (
        <Fragment>
            <NavBar />
            <Preview />
            <AboutUs />
            <WhyMdad />
            <Offers />
            <Footer />
            <ModalForm
                show={show}
                setCalendlyModalShow={setCalendlyModalShow}
                packageName={packageName}
                onHide={() => setShow(false)}
            />
            <CalendlyModal
                show={calendlyModalShow}
                packageName={packageName}
                onHide={() => setCalendlyModalShow(false)}
            />
        </Fragment>
    );
};

export default Home;
