import React, { useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import logo from '../../assets/img/jsour-logo.svg';
import { __, getLang } from '../../Helpers/Str';

const NavBar = () => {
    const [expanded, setExpanded] = useState(false);

    window.onscroll = () => {
        if (window.scrollY < 80) {
            setExpanded(true);
        } else {
            setExpanded(false);
        }
    };

    return (
        <Navbar
            bg="light"
            fixed="top"
            expand="lg"
            className={['nav-bar shadow-sm', expanded ? 'expanded' : ''].join(' ')}
            dir={getLang() === 'ar' ? 'rtl' : 'ltr'}
        >
            <Container>
                <Navbar.Brand href="/">
                    <img
                        src={logo}
                        width="250"
                        height="80"
                        className="d-inline-block align-top"
                        alt={__('Jsour')}
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse>
                    <Nav className="mx-auto">
                        <Nav.Link href="#home">{__('Home')}</Nav.Link>
                        <Nav.Link href="#about-mdad">{__('About Jsour')}</Nav.Link>
                        <Nav.Link href="#contact-us">{__('Contact us')}</Nav.Link>
                        <Nav.Link href={['/?lang=', getLang() === 'ar' ? 'en' : 'ar'].join('')}>
                            {getLang() === 'ar' ? 'en' : 'ar'}
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavBar;
