import React, { useState } from 'react';
import {
    Button,
    ButtonGroup,
    ButtonToolbar,
    Card,
    CardGroup,
    Carousel,
    Container,
    Row,
} from 'react-bootstrap';
import { __, getLang } from '../../Helpers/Str';
import quality from '../../assets/img/quality.svg';
import tech from '../../assets/img/tech.svg';
import exp from '../../assets/img/exp.svg';
import home from '../../assets/img/home.svg';
import users from '../../assets/img/users.svg';
import barChart from '../../assets/img/bar-chart.svg';

const WhyMdad = () => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => setIndex(selectedIndex);

    return (
        <div className="why-mdad bg-main py-5" dir={getLang() === 'ar' ? 'rtl' : 'ltr'}>
            <h1 className="text-primary text-center mb-5">{__('Why Jsour')}</h1>
            <div className="d-flex justify-content-center mb-5">
                <ButtonToolbar className="button-toolbar border px-3 py-2 m-0">
                    <ButtonGroup className="m-0" aria-label="Second group">
                        <Button
                            size="lg"
                            onClick={() => handleSelect(0)}
                            className={index === 1 ? 'disabled' : null}
                        >
                            {__('Operation Efficiency')}
                        </Button>
                    </ButtonGroup>
                    <ButtonGroup
                        className={['m-0', getLang() === 'ar' ? 'mr-2' : 'ml-2'].join(' ')}
                        aria-label="First group"
                    >
                        <Button
                            size="lg"
                            onClick={() => handleSelect(1)}
                            className={index === 0 ? 'disabled' : null}
                        >
                            {__('Sustainability')}
                        </Button>
                    </ButtonGroup>
                </ButtonToolbar>
            </div>
            <Container>
                <Carousel activeIndex={index} indicators={false} controls={false} interval={null}>
                    <Carousel.Item>
                        <Row className="mx-auto">
                            <CardGroup>
                                <Card className="pt-3 border-0">
                                    <Card.Img alt="vision Icon" height={100} src={tech} />
                                    <Card.Body>
                                        <h1 className="text-primary mb-3">
                                            {__('Latest Techniques')}
                                        </h1>
                                        <p>
                                            {__(
                                                'We applies the latest technologies, to enhance the performance and reduce the operational cost.'
                                            )}
                                        </p>
                                    </Card.Body>
                                </Card>
                                <Card className="pt-3 border-0">
                                    <Card.Img alt="vision Icon" height={100} src={exp} />
                                    <Card.Body>
                                        <h1 className="text-primary mb-3">
                                            {__('Competence and experience')}
                                        </h1>
                                        <p>
                                            {__(
                                                'Specialized expert team in call center management, providing  customer service representatives training according to the needs of the organization and monitoring their performance to ensure quality.'
                                            )}
                                        </p>
                                    </Card.Body>
                                </Card>
                                <Card className="pt-3 border-0">
                                    <Card.Img alt="our job Icon" height={100} src={quality} />
                                    <Card.Body>
                                        <h1 className="text-primary mb-3">
                                            {__('Quality and Performance Control')}
                                        </h1>
                                        <p>
                                            {__(
                                                'A dedicated quality management team monitor the performance of the customer service representative by reviewing recorded calls and analyzing the results of customer satisfaction measurement surveys.'
                                            )}
                                        </p>
                                    </Card.Body>
                                </Card>
                            </CardGroup>
                        </Row>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Row className="mx-auto">
                            <CardGroup>
                                <Card className="pt-4 border-0">
                                    <Card.Img alt="vision Icon" height={100} src={users} />
                                    <Card.Body>
                                        <h1 className="text-primary mb-3">
                                            {__('Social Sustainability')}
                                            <br />
                                            <small>
                                                {__('Saudi Female safe working environment')}
                                            </small>
                                        </h1>
                                        <p>
                                            {__(
                                                'Jsour contributes to granting job opportunities remotely to different groups that are not suitable for traditional methods of work, thus enhancing its social role by meeting its needs and enabling it to play an effective role in the development of the Kingdom of Saudi Arabia'
                                            )}
                                        </p>
                                    </Card.Body>
                                </Card>
                                <Card className="pt-4 border-0">
                                    <Card.Img alt="vision Icon" height={100} src={home} />
                                    <Card.Body>
                                        <h1 className="text-primary mb-3">
                                            {__('Environmental Sustainability')}
                                            <br />
                                            <small>{__('Teleworkers Agents')}</small>
                                        </h1>
                                        <p>
                                            {__(
                                                'We are adopting cloud technologies, all call center service representatives work remotely, which contributes to reducing the negative impact on the environment, by reducing the demand for transportation to reach the workplace and thus reducing traffic and air pollution associated with it.'
                                            )}
                                        </p>
                                    </Card.Body>
                                </Card>
                                <Card className="pt-4 border-0">
                                    <Card.Img alt="our job Icon" height={100} src={barChart} />
                                    <Card.Body>
                                        <h1 className="text-primary mb-3">
                                            {__('Economic Sustainability')}
                                            <br />
                                            <small>{__('100% Female Saudi potentials')}</small>
                                        </h1>
                                        <p>
                                            {__(
                                                'We are proud that all Jsour employees are Saudi female from all Saudi Arabia regions which support enabling women in the society. This initiative creates more local jobs opportunities and affects local economy growth. Therefore, the economical revenues from Jsour will return to the society you are working and leaving in.'
                                            )}
                                        </p>
                                    </Card.Body>
                                </Card>
                            </CardGroup>
                        </Row>
                    </Carousel.Item>
                </Carousel>
            </Container>
        </div>
    );
};

export default WhyMdad;
