import React from 'react';
import { Card, CardGroup, Col, Container, Row } from 'react-bootstrap';
import aboutUsIcon from '../../assets/img/jsour-logo.svg';
import visionIcon from '../../assets/img/vision-icon.svg';
import ourJobIcon from '../../assets/img/our-job-icon.svg';
import { __ } from '../../Helpers/Str';

const AboutUs = () => {
    return (
        <div className="about-us bg-main py-5" id="about-mdad">
            <Container>
                <Row className="mb-5">
                    <Col lg={6} className="mx-auto text-center">
                        <img src={aboutUsIcon} width={200} height={200} alt="about Us Icon" />
                        <h1 className="text-primary my-4">{__('About Jsour')}</h1>
                        <p>
                            {__(
                                'A Novel concept for call center services that support teleworking through presenting an innovative technical solution based on cloud computing technology to help SMEs and governmental entities in KSA provide their clients with best experience.'
                            )}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <CardGroup>
                        <Card className="bg-transparent border-0">
                            <Card.Img alt="our job Icon" height={120} src={ourJobIcon} />
                            <Card.Body>
                                <h1 className="text-primary mb-3">{__('Our Mission')}</h1>
                                <p>
                                    {__(
                                        'Invest new technologies in call centers and customers experience management solutions to fulfill private and governmental entities needs and enable them to reach the best local talents'
                                    )}
                                </p>
                            </Card.Body>
                        </Card>
                        <Card className="bg-transparent border-0">
                            <Card.Img alt="vision Icon" height={120} src={visionIcon} />
                            <Card.Body>
                                <h1 className="text-primary mb-3">{__('Our Vision')}</h1>
                                <p>
                                    {__(
                                        'Building smart connection between the customers and business owners'
                                    )}
                                </p>
                            </Card.Body>
                        </Card>
                    </CardGroup>
                </Row>
            </Container>
        </div>
    );
};

export default AboutUs;
