import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { __, getLang } from '../../Helpers/Str';
import AR from '../../assets/pdf/TermsAndConditionsAR.pdf';
import EN from '../../assets/pdf/TermsAndConditionsEN.pdf';
import phone from '../../assets/img/phone.svg';
import mail from '../../assets/img/mail.svg';
import location from '../../assets/img/location.svg';
import ContactForm from './ContactForm';

const Footer = () => {
    return (
        <div className="footer" id="connect-us">
            <div className="up-side py-6">
                <Container>
                    <Row
                        className={['d-flex', getLang() === 'ar' ? 'flex-row-reverse' : ''].join(
                            ' ',
                        )}
                    >
                        <Col lg={5}>
                            <div
                                className={[
                                    'd-flex flex-column',
                                    getLang() === 'ar' ? 'text-right' : 'text-left',
                                ].join(' ')}
                            >
                                <h3>{__('Connect us')}</h3>
                                <p className="mt-3 mb-5">
                                    {__(
                                        'In case of any inquiries, do not hesitate to contact us, Jsour team is here to serve you',
                                    )}
                                </p>
                                <div
                                    className={[
                                        'd-flex align-items-center mb-3 justify-content-start',
                                        getLang() === 'ar' ? 'flex-row-reverse' : '',
                                    ].join(' ')}
                                >
                                    <img src={phone} alt="phone" />
                                    <p className="mx-3 my-auto">
                                        <a href="tel:920023600">920023600</a>
                                    </p>
                                </div>
                                <div
                                    className={[
                                        'd-flex align-items-center mb-3 justify-content-start',
                                        getLang() === 'ar' ? 'flex-row-reverse' : '',
                                    ].join(' ')}
                                >
                                    <img src={mail} alt="mail" />
                                    <p className="mx-3 my-auto">
                                        <a href="mailto:info@jsour.sa">
                                            info@jsour.sa
                                        </a>
                                    </p>
                                </div>
                                <div
                                    className={[
                                        'd-flex align-items-center mb-3 justify-content-start',
                                        getLang() === 'ar' ? 'flex-row-reverse' : '',
                                    ].join(' ')}
                                >
                                    <img src={location} alt="location" />
                                    <p className="mx-3 my-auto">Al Olaya, Riyadh 12244</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="bg-primary text-light py-6" id="contact-us">
                <Container>
                    <ContactForm />
                    <div
                        className={[
                            'd-flex py-4',
                            getLang() === 'ar' ? 'justify-content-end' : 'justify-content-start',
                        ].join(' ')}
                    >
                        <p>{__('Connect us')}</p>
                    </div>
                    <div
                        className={[
                            'd-flex',
                            getLang() === 'ar' ? 'justify-content-end' : 'justify-content-start',
                        ].join(' ')}
                    >
                        <a
                            href="https://www.linkedin.com/showcase/jsour_sa/about"
                            className="btn btn-outline-light rounded-circle mx-1"
                        >
                            <i className="fab fa-linkedin-in" />
                        </a>
                        <a
                            href="https://twitter.com/Jsour_sa"
                            className="btn btn-outline-light rounded-circle mx-1"
                        >
                            <i className="fab fa-twitter" />
                        </a>
                        <a
                            href="https://www.instagram.com/Jsour_sa/"
                            className="btn btn-outline-light rounded-circle mx-1"
                        >
                            <i className="fab fa-instagram" />
                        </a>
                    </div>
                </Container>
            </div>
            <div className="bg-primary border-top border-light text-light">
                <Container>
                    <div className="d-flex justify-content-between py-4 copyright">
                        <div className="text-left">© 2019 Tamkeen Technologies</div>
                        <a
                            href={getLang() === 'ar' ? AR : EN}
                            className="text-light"
                            download={
                                getLang() === 'ar'
                                    ? 'TermsAndConditionsAR.pdf'
                                    : 'TermsAndConditionsEN.pdf'
                            }
                        >
                            {__('Privacy policy')}
                        </a>
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default Footer;
