import React from 'react';
import { __, getLang } from '../../Helpers/Str';
import { Button, Card, CardGroup, Carousel, Row } from 'react-bootstrap';
import customerService from '../../assets/img/customer_service.svg';
import bookLover from '../../assets/img/book_lover.svg';
import calling from '../../assets/img/calling.svg';
import dataProcess from '../../assets/img/data_process.svg';

const Offers = () => {
    const prevIcon = (
        <Button className="controls prev-icon">
            <svg
                width="2em"
                height="2em"
                viewBox="0 0 16 16"
                className="bi bi-chevron-left"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                />
            </svg>
        </Button>
    );

    const nextIcon = (
        <Button className="controls prev-icon">
            <svg
                width="2em"
                height="2em"
                viewBox="0 0 16 16"
                className="bi bi-chevron-right"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                />
            </svg>
        </Button>
    );

    return (
        <div className="offers bg-main py-5" dir={getLang() === 'ar' ? 'rtl' : 'ltr'}>
            <h1 className="text-primary text-center mb-5">{__('Our Offers to Our Clients')}</h1>
            <Carousel indicators={false} prevIcon={prevIcon} nextIcon={nextIcon} interval={null}>
                <Carousel.Item>
                    <Row>
                        <CardGroup className="mx-auto">
                            <Card className="pt-3 border-0">
                                <Card.Img alt="vision Icon" height={300} src={bookLover} />
                                <Card.Body>
                                    <h1 className="text-primary mb-3">{__('Training Courses')}</h1>
                                    <p>
                                        {__(
                                            'Periodical training courses to the customer’s services representatives to ensure the performance quality.'
                                        )}
                                    </p>
                                </Card.Body>
                            </Card>
                            <Card className="pt-3 border-0">
                                <Card.Img alt="vision Icon" height={300} src={customerService} />
                                <Card.Body>
                                    <h1 className="text-primary mb-3">
                                        {__('Customer Services Representatives')}
                                    </h1>
                                    <p>
                                        {__(
                                            'Customer services representatives trained on presenting the company’s identity, products, and values effectively and professionally.'
                                        )}
                                    </p>
                                </Card.Body>
                            </Card>
                        </CardGroup>
                    </Row>
                </Carousel.Item>
                <Carousel.Item>
                    <Row>
                        <CardGroup className="mx-auto">
                            <Card className="pt-3 border-0">
                                <Card.Img alt="vision Icon" height={300} src={dataProcess} />
                                <Card.Body>
                                    <h1 className="text-primary mb-3">
                                        {__('Modern digital systems')}
                                    </h1>
                                    <p>
                                        {__(
                                            'Cloud based system to monitor the performance of the service representatives and generate periodic reports in order to ensure the quality of service.'
                                        )}
                                    </p>
                                </Card.Body>
                            </Card>
                            <Card className="pt-3 border-0">
                                <Card.Img alt="vision Icon" height={300} src={calling} />
                                <Card.Body>
                                    <h1 className="text-primary mb-3">{__('Unified Number')}</h1>
                                    <p>
                                        {__(
                                            'Unified number starts with 9200, which makes the communication with the clients easier and more efficient.'
                                        )}
                                    </p>
                                </Card.Body>
                            </Card>
                        </CardGroup>
                    </Row>
                </Carousel.Item>
            </Carousel>
        </div>
    );
};

export default Offers;
