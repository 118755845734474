import React from 'react';
import { Modal } from 'react-bootstrap';
import { __ } from '../../Helpers/Str';

const CalendlyModal = ({ show, packageName, onHide }) => {
    const onEnter = () => {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
        head.appendChild(script);
    };

    const dataUrl = packageName.startsWith('MDAD Calls')
        ? 'https://calendly.com/mdad/mdad-system'
        : 'https://calendly.com/mdad/mdad-service';

    return (
        <Modal
            show={show}
            size="xl"
            onEnter={onEnter}
            onHide={onHide}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header closeButton>{__('Bock a Demo')}</Modal.Header>
            <Modal.Body>
                <div
                    className="calendly-inline-widget"
                    data-url={dataUrl}
                    style={{ minWidth: 320, height: '83vh' }}
                />
            </Modal.Body>
        </Modal>
    );
};

export default CalendlyModal;
