import React, { useState } from 'react';
import { Button, Col, Container, Form, Modal } from 'react-bootstrap';
import { __, getLang } from '../../Helpers/Str';
import Swal from 'sweetalert2';
import axios from 'axios';
import * as Sentry from '@sentry/react';

const ModalForm = ({ show, onHide, packageName, setCalendlyModalShow }) => {
    const [message, setMessage] = useState({});
    const [errors, setErrors] = useState({});
    const [sending, setSending] = useState(false);

    const calendlyEnabled = false;

    const onChange = (e) => setMessage({ ...message, [e.target.name]: e.target.value });

    const onSelectChange = (e) => {
        const { value } = e.target;

        let addsOn = message.adds_on;

        if (!addsOn) {
            setMessage({ ...message, adds_on: [value] });
        } else if (addsOn.includes(value)) {
            addsOn.splice(addsOn.indexOf(value), 1);

            setMessage({ ...message, adds_on: [...addsOn] });
        } else {
            setMessage({ ...message, adds_on: [...addsOn, value] });
        }
    };

    const addsOn = () => {
        const addsOn = ['IVR recording', 'Extra calls', 'Training'];

        return addsOn.map((addOn) => {
            return (
                <option value={addOn} key={Math.random()}>
                    {__(addOn)}
                </option>
            );
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();

        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            icon: 'error',
            title: __('Something wrong happened'),
        });

        setSending(true);
        setErrors({});

        const data = {
            ...message,
            request_type: 498,
            package_type: packageName,
        };

        axios
            .post(`create?lang=${getLang()}`, data)
            .then(() => {
                Toast.fire({
                    title: __('Your request sent successfully'),
                    icon: 'success',
                });

                setMessage({});
                onHide();
                if (calendlyEnabled) {
                    setCalendlyModalShow(true);
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 400 && error.response.data.errors) {
                        setErrors(error.response.data.errors);
                    } else {
                        Sentry.captureException(error);
                        Toast.fire();
                    }
                } else {
                    Sentry.captureException(error);
                    Toast.fire();
                }
            })
            .finally(() => setSending(false));
    };

    const renderErrors = (key) =>
        isInvalid(key) ? (
            <Form.Control.Feedback type="invalid">
                <ul>{errors[key]}</ul>
            </Form.Control.Feedback>
        ) : null;

    const isInvalid = (key) => (errors ? errors[key] : false);

    return (
        <Modal
            show={show}
            size="lg"
            onHide={() => {
                onHide();
                setMessage({});
            }}
        >
            <Modal.Body>
                <Container>
                    <Form onSubmit={onSubmit} className="text-right" dir="rtl">
                        <Form.Row className="mb-3">
                            <Form.Group as={Col} lg={6}>
                                <Form.Label className="required">{__('First name')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="first_name"
                                    isInvalid={isInvalid('first_name')}
                                    value={message.first_name || ''}
                                    onChange={onChange}
                                    placeholder={__('First name')}
                                    disabled={sending}
                                    required
                                />
                                {renderErrors('first_name')}
                            </Form.Group>
                            <Form.Group as={Col} lg={6}>
                                <Form.Label className="required">{__('Last name')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="last_name"
                                    isInvalid={isInvalid('last_name')}
                                    value={message.last_name || ''}
                                    onChange={onChange}
                                    placeholder={__('Last name')}
                                    disabled={sending}
                                    required
                                />
                                {renderErrors('last_name')}
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="mb-3">
                            <Form.Group as={Col} lg={6}>
                                <Form.Label className="required">{__('Mobile number')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="mobile_number"
                                    isInvalid={isInvalid('mobile_number')}
                                    value={message.mobile_number || ''}
                                    onChange={onChange}
                                    placeholder={__('Mobile number')}
                                    disabled={sending}
                                    required
                                />
                                {renderErrors('mobile_number')}
                            </Form.Group>
                            <Form.Group as={Col} lg={6}>
                                <Form.Label className="required">{__('Email address')}</Form.Label>
                                <Form.Control
                                    type="email"
                                    inputMode="email"
                                    name="email"
                                    isInvalid={isInvalid('email')}
                                    value={message.email || ''}
                                    onChange={onChange}
                                    placeholder={__('Email address')}
                                    disabled={sending}
                                    required
                                />
                                {renderErrors('email')}
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="mb-3">
                            <Form.Group as={Col} lg={6}>
                                <Form.Label className="required">{__('Company name')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="company_name"
                                    isInvalid={isInvalid('company_name')}
                                    value={message.company_name || ''}
                                    onChange={onChange}
                                    placeholder={__('Company name')}
                                    disabled={sending}
                                    required
                                />
                                {renderErrors('company_name')}
                            </Form.Group>
                            <Form.Group as={Col} lg={6}>
                                <Form.Label className="required">
                                    {__('Company website')}
                                </Form.Label>
                                <Form.Control
                                    type="url"
                                    inputMode="url"
                                    name="company_website"
                                    isInvalid={isInvalid('company_website')}
                                    value={message.company_website || ''}
                                    onChange={onChange}
                                    placeholder={__('Company website')}
                                    disabled={sending}
                                    required
                                />
                                {renderErrors('company_website')}
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="mb-3">
                            <Form.Group as={Col} lg>
                                <Form.Label className="required">{__('Company size')}</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="company_size"
                                    isInvalid={isInvalid('company_size')}
                                    value={message.company_size}
                                    onChange={onChange}
                                    disabled={sending}
                                    custom
                                    required
                                >
                                    <option value="">{__('Select company size')}</option>
                                    <option value="1-10">1-10</option>
                                    <option value="11-30">11-30</option>
                                    <option value="31-50">31-50</option>
                                    <option value="101-300">101-300</option>
                                    <option value="+300">+300</option>
                                </Form.Control>
                                {renderErrors('company_size')}
                            </Form.Group>
                            {packageName.startsWith('MDAD Calls') ? null : (
                                <Form.Group as={Col} lg>
                                    <Form.Label className="required">
                                        {__('Working shift')}
                                    </Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="working_shift"
                                        isInvalid={isInvalid('working_shift')}
                                        value={message.working_shift || ''}
                                        onChange={onChange}
                                        disabled={sending}
                                        custom
                                        required
                                    >
                                        <option value="">{__('Select working shift')}</option>
                                        <option value="8-4">8-4</option>
                                        <option value="9-5">9-5</option>
                                    </Form.Control>
                                    {renderErrors('working_shift')}
                                </Form.Group>
                            )}
                        </Form.Row>
                        <Form.Row className="mb-5">
                            {packageName.startsWith('MDAD Calls') ? null : (
                                <Form.Group as={Col} lg>
                                    <Form.Label className="required">
                                        {__('Average number of calls monthly')}
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="average_number_of_calls_monthly"
                                        isInvalid={isInvalid('average_number_of_calls_monthly')}
                                        value={message.average_number_of_calls_monthly || ''}
                                        onChange={onChange}
                                        disabled={sending}
                                        placeholder={__('Average number of calls monthly')}
                                        required
                                    />
                                    {renderErrors('average_number_of_calls_monthly')}
                                </Form.Group>
                            )}
                            <Form.Group as={Col} lg>
                                <Form.Label>
                                    {__('Adds on')}
                                    {!message.adds_on ? null : message.adds_on.length ? (
                                        <Button
                                            variant="link"
                                            size="sm"
                                            className="py-0"
                                            onClick={() =>
                                                setMessage({
                                                    ...message,
                                                    adds_on: undefined,
                                                })
                                            }
                                        >
                                            {__('Clear selected')}
                                        </Button>
                                    ) : null}
                                </Form.Label>
                                <Form.Control
                                    as="select"
                                    name="adds_on"
                                    isInvalid={isInvalid('adds_on')}
                                    value={message.adds_on || []}
                                    onChange={onSelectChange}
                                    disabled={sending}
                                    htmlSize={3}
                                    multiple
                                    custom
                                >
                                    {addsOn()}
                                </Form.Control>
                                {renderErrors('adds_on')}
                            </Form.Group>
                        </Form.Row>
                        <Button type="submit" disabled={sending} size="lg" block>
                            {__('Send')}
                        </Button>
                        <Button
                            type="button"
                            variant="secondary"
                            size="lg"
                            disabled={sending}
                            onClick={() => {
                                onHide();
                                setErrors({});
                                setMessage({});
                            }}
                            block
                        >
                            {__('Close')}
                        </Button>
                    </Form>
                </Container>
            </Modal.Body>
        </Modal>
    );
};

export default ModalForm;
